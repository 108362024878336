import React from "react"
import PropTypes from "prop-types"
import "./sociallinks.scss"

import { FaGithub, FaTwitter, FaEnvelope, FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa"



// Edit these to add or remove social media icons.
const socialMediaAccounts = [
	{ id: 1, icon: FaGithub, url: "http://github.com/boscorona" },
	{
		id: 2,
		icon: FaLinkedin,
		url: "https://linkedin.com/in/boscohelpedme",
	},
	{
		id: 3,
		icon: FaTwitter,
		url: "https://twitter.com/boscosco",
	},
	{ id: 4, icon: FaEnvelope, url: "mailto:bosco@boscorona.com" },
	{ id: 5, icon: FaFacebook, url: "https://www.youtube.com/watch?v=iik25wqIuFo" },
	{ id: 6, icon: FaInstagram, url: "https://www.youtube.com/watch?v=T-91z9h7y54&ab_channel=JoeRoganClips" },
]

const SocialLinks = ({ className }) => (
	<div className={`SocialLinks ${className}`}>
		{socialMediaAccounts.map(account => (
			<a
				href={account.url}
				key={account.id}
				className={`SocialLinks__container ${className}__container`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<account.icon className={`SocialLinks__icon ${className}__icon`} />
			</a>
		))}
	</div>
)

export default SocialLinks
